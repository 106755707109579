
.solde h1{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: -10px; */
}

.home-screens-container {
    background-color: #E72D32;
    flex: 1;
    padding: 10px;
    color: white;
  }
  
  .profile-notification {
    display: flex;
    justify-content: space-between;

  
      position: sticky;
       top: 0;
       z-index: 100px;
       background: "#E72D32";
       border-bottom-left-radius: 50%;
       border-bottom-right-radius: 50%;
    
  }
  
  .profile-img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
  }
  
  .notification-img {
    width: 110px;
    height: 110px;
    /* margin-top: 15px; */
  }
  
  .solde-section {
    text-align: center;
    color: #ffffff;
    font-weight: 300;
    font-size: 20px;
    margin-top: -20px;
  }
  
  .montant {
    color: #ffffff;
    font-size: 30px;
    font-weight: 500;
   
  }
  
  .after-solde {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 60px;
  }

  
  .after-kpi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    margin-top: 10px;
  }

  
  .list-title {
    color: #000000;
    font-weight: 400;
    font-size: 16px;
  }
  
  .commande-list {
    /* display: flex; */
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 10px;  
    margin-top: 20px;
    min-height: 500px;
}
.ajoutCommande{
padding: 6px;
justify-content: center;
align-items: center;
border-radius: 5px;
border: 1px solid #F1F1F1;
}
.donnees{
  width: 200px;
  height: 350px; 
  margin-top: 50px;
  color: red;
}

.fixed-button {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  z-index: 1000; 
}
.coll{
  color: #E72D32 !important;
}
.home-screen{
  background-color: #F3F3F3;
  padding: 10px;
  /* min-height: 1000px; */
}
.seletedStatus{
  padding: 5px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  width: 97%;
}
p{
margin-bottom: 0;
}