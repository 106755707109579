.btn-link {
    text-decoration: none;
  }

  .inputStyle{
    width: 150px;
  }
  .header-red{
    background-color: #E82828;
  }
  .image-de-profil img{
    width: 125px;
  }
  .in-line img{
    width: 125px;
  }
  .client-number{
    background-color:#E82828;
   
  }
  .card-body {
    padding: 0; /* Supprime tout le padding à l'intérieur de la carte */
  }
 
  .nom-du-client {
    color: #000;
    font-family: Montserrat;
    font-size: 15px;
    padding-left: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .bgCol{
    width: 50px;
    background-color: gray;
  }
  .inputSearch{
    padding: 10px;
  }
  .home-screens{
    background-color: #F3F3F3;
    padding: 10px;
    /* min-height: 1000px; */
 }
  .commande-listst{
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 10px;  
    margin-top: 20px;
    min-height: 486px;
  }
  a{
    text-decoration: none !important;
  }
 
  