.alert-modal {
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px; */
    /* z-index: 9999;
    text-align: center; */
    width: 500px;
  }
  .text-form{
    font-weight: 400;
    font-size: 14;
}
.a{
  text-decoration: none;
}
  