.histo{
  display: flex;
  align-items:center;
  justify-content: space-between;
  margin:5px;
  box-shadow: 0px 4.032px 30.24px 0px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF; /* Retirez les guillemets doubles ici */
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.05);
}