
.solde h1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
}

.home-screens-container {
    background-color: #E72D32;
    flex: 1;
    padding: 10px;
    color: white;
  }
  
  .profile-notification {
    display: flex;
    justify-content: space-between;
  }
  
  .profile-img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
  }
  
  .solde-sections {
    text-align: center;
    color: #ffffff;
    font-weight: 300;
    font-size: 20px;
    margin-top: -30px;
  }
  
  .montants {
    color: #ffffff;
    font-size: 30px;
    font-weight: 500;
   
  }
  
  .after-soldes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 60px;
  }
  
  .visibility-buttons {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #ffffff;
    margin-top: -15px;
    
  }
  
  .cart-kpis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
    padding: 15px;
    margin-top: -8px;
    background: #FFF;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  
  .icon-kpis {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text-kpi {
    color: #3F3F3F;
    font-weight: 300;
    font-size: 13px;
  }
  
  .after-kpis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 30px;
    /* margin-top: 10px; */
  }
  .icona{
    color: #E82828;
    font-size: 24px;
  }
  
  .list-title {
    color: #000000;
    font-weight: 400;
    font-size: 16px;
  }
  
  .commande-lists {
    /* max-width: 500px; La largeur maximale est de 500px */
     /* Pour s'adapter aux écrans plus petits que 500px */
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 10px;
    min-height: 320px;
    /* margin: auto; */
  }
  
  .sousDiv{
    position: sticky;
     top: 0;
     z-index: 100px;
     background: "#E72D32";
     border-bottom-left-radius: 50%;
     border-bottom-right-radius: 50%;
  }
  a {
    text-decoration: none;
  }
 /* kpi accueil */
 .sec1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;
    /* width: 220px; */
    height: 82.454px;
    margin: 7px;
    /* gap: 15px; */
    padding: 12px;
    border-radius: 5.211px;
    border: 0.261px solid rgba(218, 218, 218, 0.30);
    background: #FFF;
    box-shadow: 0px 1.04222px 10.42223px 0px rgba(219, 68, 55, 0.05);
    font-family: montserrat;

 }
 .kpi-home{
  display: flex;
  max-width: 500px;
 }
 .homes{
  background-color: #F3F3F3;
    padding: 10px;
    max-width: 500px;
    
 }
 .iconss{
  font-size: 40px !important;
  font-family: Montserrat;
 }
 .lessolde{
  color: #383639;
  font-family: Montserrat;
  font-size: 16.558px;
  font-style: normal;
  font-weight: 600;
 }
 .solde-text{
  color: #383639;
  text-align: left;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  font-family: Montserrat;
 }
 .aligne{
  align-items: start;
  font-family: Montserrat;
 }
